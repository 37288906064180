<template>
	<div class="mallPagemange">
		<!-- <el-tabs v-model="activeName" v-if="IsOpenCommunity" @tab-click="handleFilter">
		    <el-tab-pane label="商城页面管理" name="shop"></el-tab-pane> 
		    <el-tab-pane label="社群福利页管理" name="group"></el-tab-pane>
		  </el-tabs> -->
		
		<div class="filter-container" style="position: relative;padding-right: 140px;">
			<!-- 关键字搜索 -->
			<div class="filter-item">
				<label class="label">关键字: </label>
				<el-input v-model="searchKey" placeholder="请输入页面名称" maxlength="20" style="width: 300px;"></el-input>
			</div>
			<div class="filter-item">
				<el-button type="primary" style="margin-left:30px" @click="handleFilter">查询</el-button>
				<el-button type="primary" style="margin-left:30px" @click="addFright({})">添加</el-button>
			</div>
			
			<div style="position: absolute;right: 20px;top: 0px;">
				<experience-code></experience-code>
			</div>
			
		</div>
		<!-- 表格 -->
		<div class="table-container">
			<el-table :data="groupData" style="width: 100%;" v-loading="loading" ref="multipleTable">
				<el-table-column prop="Name" label="页面名称">
					<template slot-scope="scope">
						<span style="padding-right: 10px;">{{scope.row.PageName}}</span>
						<el-tag size="mini" v-if="activeName=='shop' && scope.row.IsIndexPage">首页</el-tag>
						<el-tag size="mini" v-if="activeName!='shop' && scope.row.IsIndexPage">当前使用</el-tag>
						<el-tag size="mini"  v-if="scope.row.AppointmentHomeTime">{{scope.row.AppointmentHomeTime}}设为首页</el-tag>
					</template>
				</el-table-column>
				<el-table-column prop="AddTime" label="创建时间"></el-table-column>
				<el-table-column label="操作" width="260">
					<template slot-scope="scope">
						<el-button type="text" @click="addFright(scope.row)">编辑</el-button>
						
						<button-permissions :datas="'pagesTg'">
							<el-button type="text" @click="weiCodeFun(scope.row.Id)" style="margin-left: 15px;" class="pointers">推广</el-button>
						</button-permissions>
						
						<button-permissions :datas="'asHomePageBtn'"  style="margin-left: 15px;" v-if="activeName=='shop' && !scope.row.IsIndexPage">
							<el-dropdown  >
								<el-button  type="text">
									设为首页
								</el-button>
								<el-dropdown-menu slot="dropdown">
									
									<el-dropdown-item >
										<el-button type="text" @click="setIndex(scope.row)" >立即设为首页</el-button>
									</el-dropdown-item>

									<el-dropdown-item >
										<el-button type="text" @click="setIndexTime(scope.row.Id)" >定时设为首页</el-button>
									</el-dropdown-item>

								</el-dropdown-menu>
							</el-dropdown>
						</button-permissions>
						
						<el-button type="text" @click="setIndex(scope.row)"  style="margin-left: 15px;" v-if="activeName!='shop' && !scope.row.IsIndexPage">使用该页</el-button>
						
						<el-button type="text" @click="handleCopy(scope.row)"  style="margin-left: 15px;">复制</el-button>
						
						<span style="color:#f00;margin-left:10px;cursor: pointer;" v-if="!scope.row.IsIndexPage" @click="deleteChose(scope.row)">删除</span>
					</template>
				</el-table-column>
			</el-table>
			<div style="text-align: right;">
				<el-pagination v-if="total" style="margin:20px 0" @size-change="handleSizeChange" @current-change="handleCurrentChange"
				 :current-page="pageIndex" :page-sizes="[10, 20, 30, 40, 50]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
				 :total="total">
				</el-pagination>
			</div>
		</div>

		<!-- 推广的弹框 -->
		<spread-dialog :visible="spreadVisible" :spreadPageId="spreadPageId" @close="spreadVisible=false"></spread-dialog>
		
		
		<!-- <batch-cods :weiCodeVisivble="weiCodeShow" :weiCodeData="weiCodeData" @cancel="weiCodeCancel"></batch-cods> -->

		
		<!-- //时间弹窗 -->
		<el-dialog :visible.sync="setTimeVisible" width="500px" title="定时设为首页">
			<div style="height:300px;">
				<span>设为首页时间：</span>
				<el-date-picker type="datetime" placeholder="选择时间" v-model="startTime" style="width: 200px;" value-format="yyyy-MM-dd HH:mm:ss"
				 format="yyyy-MM-dd HH:mm:ss" :picker-options='maxOptions'></el-date-picker>
			</div>
			<div style="text-align: center;margin-top:30px;">
				<el-button type="primary" style="margin-right:10px;width: 120px;" @click="saveSetTime">保存</el-button>
			</div>
		</el-dialog>
		
		
		<!-- 页面模板弹框 -->
		<el-dialog :visible.sync="visible" width="1000px" title="添加新页面">
			<div class="modal-list">
				<div class="modal-item-add" @click="toAdd">
					<div><i class="el-icon-plus" style="font-size: 30px;margin-bottom: 20px;"></i></div>
					<div>自定义页面模块</div>
				</div>
				<div class="modal-item" v-for="item in modalList" :key="item.id" @click="toUseModal(item)" @mouseenter="item.hover=true" @mouseleave="item.hover=false">
					<img class="bg" :src="item.bg"></img>
					<div class="use-box" :class="{useBoxHover: item.hover}">
						<el-button type="primary">使用该模板</el-button>
					</div>
				</div>
			</div>
		</el-dialog>

	</div>
</template>

<script>
	import {
		mallPageList,
		setHome,
		decorateDelete,
		editInit,
		editSave,
		appointmentSetHome
	} from '../../api/pageManage.js';
	import batchCods from "@/components/bathCodes"
	import buttonPermissions from '@/components/buttonPermissions';
	import experienceCode from '@/components/experienceCode';
	import spreadDialog from './components/spreadDialog.vue';
	
	import {
		templateList
	} from './data.js';
	
	import {
		mapState
	} from 'vuex';

	export default {
		components: {
			batchCods,
			buttonPermissions,
			experienceCode,
			spreadDialog
		},
		data() {
			return {
				weiCodeData: {},
				weiCodeShow: false,
				searchKey: '',
				groupData: [],
				pageIndex: 1,
				pageSize: 20,
				total: 0,
				loading: false,
				activeName: 'shop',
				visible: false,
				
				maxOptions: {
					disabledDate(time) {
						return time.getTime() < Date.now() - 8.64e7;
					},
				},
				startTime: '',
				setTimeVisible: false,
				currentTimeId: null,
				
				modalList: templateList,
				
				spreadVisible: false,
				spreadPageId: 0
				
			}
		},
		computed: {
			...mapState({
				IsOpenCommunity: (state)=>{
					return state.user.IsOpenCommunity
				}
			})
		},
		beforeMount() {
			this.getList();
		},
		methods: {
			// 推广
			weiCodeFun(ProductId) {
				// this.weiCodeShow = true;
				// this.weiCodeData = {
				// 	Page: `pages/cusPage/cusPage`,
				// 	Scene: `id=${ProductId}`
				// }
				this.spreadVisible = true;
				this.spreadPageId = ProductId;
			},
			weiCodeCancel(value) {
				this.weiCodeShow = value;
			},
			handleFilter() {
				this.pageIndex = 1;
				this.getList();
			},
			// 获取列表数据
			async getList() {

				this.loading = true;
				try {
					let data = {
						Keywords: this.searchKey,
						Skip: (this.pageIndex - 1) * this.pageSize, // 略过数据
						Take: this.pageSize, // 取的数据
						IsWeixinGroupPage: this.activeName === 'group'
					}

					let res = await mallPageList(data);

					this.total = res.Result.Total;
					this.groupData = res.Result.Results || [];

				} catch (error) {
					console.log(error);
				} finally {
					this.loading = false;
				}
			},

			// 编辑和新增
			addFright(record, type) {
			
				if (!record.Id){
					this.visible = true;
					return;
				}
				
				this.$router.push({
					path: '/mallPage/mallPageDetails',
					query: {
						id: record.Id ? record.Id : 0,
						type: type,
						isGroup: this.activeName
					}
				});

			},
			toAdd(){
				this.$router.push({
					path: '/mallPage/mallPageDetails',
					query: {
						id: 0
					}
				});
			},
			toUseModal(record){
				this.$router.push({
					path: '/mallPage/mallPageDetails',
					query: {
						templateId: record.id
					}
				});
			},

			// 设为首页
			async setIndex(record) {
				let res = await setHome({
					Id: record.Id,
					IsWeixinGroupPage: this.activeName === 'group'
				});

				if (res.IsSuccess) {
					this.$message({showClose: true,
						message: '设置成功',
						type: 'success'
					});
					this.getList();
				} else {
					this.$message({showClose: true,
						message: res.Message,
						type: 'error'
					});
				}
			},
			
			//设置上架时间
			setIndexTime(id) {
				this.currentTimeId = id
				this.setTime = ''
				this.setTimeVisible = true
			},
			
			//保存修改时间
			async saveSetTime() {
				try {
					this.loading = true
					let data = {
						Id: this.currentTimeId,
						AppointmentHomeTime: this.startTime,
					}
					console.log('data',data)
					let result = await appointmentSetHome(data)
					if (result.IsSuccess) {
						this.$message({
							showClose: true,
							type: 'success',
							message: '操作成功'
						});
						this.setTimeVisible = false
						this.getList()
					}
				} catch (err) {
				} finally {
					this.loading = false
				}
			},

			// 删除
			async deleteChose(record) {
				this.delteLoading = true
				this.$confirm('是否确认确认删除页面?删除后不可恢复', '提示', {
					confirmButtonText: '确认删除',
					cancelButtonText: '关闭',
					type: 'warning'
				}).then(async () => {
					let data = {
						Id: record.Id
					}
					let res = await decorateDelete(data);
					if (res.IsSuccess) {
						this.$message({showClose: true,
							type: 'success',
							message: '删除成功!'
						});
						this.handleFilter()
					}
				}).catch(() => {
					// this.$message({showClose: true,
					// 	type: 'info',
					// 	message: '已删除'
					// });
				}).finally(() => {
					
				})
			},

			// 切换显示条数
			handleSizeChange(val) {
				this.pageSize = val;
				this.getList();
			},
			// 翻页
			handleCurrentChange(val) {
				this.pageIndex = val;
				this.getList();
			},
			//复制
			async handleCopy(record){
				const params = {
					Id: record.Id,
					IsWeixinGroupPage: this.activeName === 'group'
				}
				
				const res = await editInit(params);
				
				try {
					var list = JSON.parse(res.Result.DecorateData);
					
					list.map(item=>{
						
						item.uid = this.guid();
						
						if(item.moduleType == 17){
							if (item.data.list && item.data.list.Results){
								item.data.list = item.data.list.Results;
							}else{
								item.data.list = []
							}
						}
						
						return item;
					})
					
					this.submit(list);
				} catch (error) {
				}
			},
			async submit(arr) {
				var list = JSON.parse(JSON.stringify(arr));
				var array = [];
				list.map(item=>{
					if (item.moduleType == 17){
						item.data.list = item.data.list.map(obj=>{
							return {
								ProductId: obj.ProductId
							}
						})
					}
					if (item.moduleType == 2){
						item.data.list = item.data.list.map(obj=>{
							return {
								Id: obj.Id
							}
						})
						item.data.classifylist = item.data.classifylist.map(obj=>{
							obj.ProductList = obj.ProductList.map(i=>{
								return {
									Id: i.Id
								}
							})
							return obj;
						})
						item.data.shopClassifylist = item.data.shopClassifylist.map(obj=>{
							obj.ProductList = obj.ProductList.map(i=>{
								return {
									Id: i.Id
								}
							})
							return obj;
						})
						
						if (item.data.moduleType == 'shop' && item.data.shopType == 'shop'){
							item.data.classifylist = [];
							item.data.shopClassifylist = [];
						}else if (item.data.moduleType == 'shop' && item.data.shopType == 'classify'){
							item.data.list = [];
							item.data.classifylist = [];
						}else{
							item.data.list = [];
							item.data.shopClassifylist = [];
						}
					}
					array.push(item)
				})
				
				
				const params = {
					Id: 0,
					DecorateData: JSON.stringify(array),
					PageName: array.filter(item => {
						return item.moduleType == 1;
					})[0].data.title,
					IsRelease: false,
					IsWeixinGroupPage: this.activeName === 'group'
				}
			
				const result = await editSave(params);
			
				if (result.IsSuccess) {
					
					this.getList();
			
				} else {
					this.$message.error(result.Message);
				}
			
			},
			guid() {
				var a = function() {
					return (65536 * (1 + Math.random()) | 0).toString(16).substring(1)
				};
				return 'a' + a() + a() + "-" + a() + "-" + a() + "-" + a() + "-" + a() + a() + a()
			}


		}
	}
</script>

<style lang="less" scoped>
	
	
	
	
	.mallPagemange {
		width: 100%;
		background: #fff;
		padding: 15px;
	}
	
	.modal-list{
		display: flex;
		flex-wrap: wrap;
		padding-left: 20px;
		height: 70vh;
		overflow: auto;
		
		.modal-item-add{
			width: 180px;
			height: 320px;
			border: 1px solid #eee;
			font-size: 16px;
			color: #409EEF;
			margin-bottom: 30px;
			margin-right: 40px;
			cursor: pointer;
			
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
		}
		
		.modal-item{
			position: relative;
			width: 180px;
			height: 320px;
			margin-right: 40px;
			overflow: hidden;
			border: 1px solid #eee;
			margin-bottom: 30px;
			
			.bg{
				display: block;
				width: 100%;
				height: 100%;
			}
			.use-box{
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				background-color: rgba(0,0,0,.6);
				
				display: flex;
				align-items: center;
				justify-content: center;
				
				transition: all .3s;
				transform: translateY(100%);
			}
			.useBoxHover{
				transform: translateY(0);
			}
		}
	}
</style>
